export enum ESettingsType {
  MESSAGE_DEFAULT = 'message_default',
  MESSAGE_SUCCESS = 'message_success',
  MESSAGE_ERROR = 'message_error',
  EMAIL_CONTACT = 'email_contact',
  WHATSAPP = 'whatsapp'
}

export enum ECalendarStatus {
  DEFAULT = 'default',
  VALID = 'valid',
  INVALID = 'invalid'
}