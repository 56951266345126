export const rem = (value: number | number[], baseValue = 16) => {
  if (Array.isArray(value)) {
    let result = '';
    for (const elem of value) {
      result += elem / baseValue + 'rem ';
    }
    return result;
  }
  return value / baseValue + 'rem';
};

export const vw = (value: number, baseWidth = 376) => {
  if (!value) {
    return 0;
  }
  return Math.round(((100 * value) / baseWidth) * 100) / 100 + 'vw';
}