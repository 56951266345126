import styled from 'styled-components';

import { rem, vw } from '@/styles';

export const Container = styled.div`
  max-width: ${rem(515)};
  min-height: ${rem(410)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PersonContainer = styled.div``;


export const LinkUnderline = styled.a`
  position: relative;
  &:after {
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #fff;
    left: 0;
    bottom: 1px;
    opacity: 0;
  }
  &:hover:after {
    content: '';
    opacity: 1;
  }
`;

export const FullName = styled.h3`
  font-weight: 400;
  font-size: ${rem(24)};
  font-style: normal;
  line-height: 1.64;
  margin: 0;
`;

export const JobPosition = styled.h4`
  font-weight: 400;
  font-size: ${rem(24)};
  font-family: '-OCBartok-Regular';
  font-style: normal;
  line-height: 100%;
  text-transform: lowercase;
  margin: 0;
`;

export const RangeInfo = styled(JobPosition)``;

export const Paragraph = styled.p`
  font-size: ${rem(24)};
  line-height: 1.64;
`;

export const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const MailToUs = styled(LinkUnderline)`
  font-size: ${rem(24)};
  line-height: 1.64;
  border-radius: ${rem(66)};
  padding: ${rem(16)} ${rem(24)};
`;

export const SeparatorElement = styled.span`
  min-width: ${rem(56)};
  font-size: ${rem(24)};
  line-height: 1.64;
  text-align: center;
`;

export const WhatsApp = styled(LinkUnderline)`
  font-size: ${rem(24)};
  line-height: 1.64;
  text-align: center;
  padding: ${rem(16)} ${rem(12)};
`;
