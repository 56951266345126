import styled from 'styled-components';

import { vw } from '@/styles';

export const Container = styled.div`
  padding: ${vw(35)} ${vw(35)} ${vw(20)};
`;

export const FullName = styled.h3`
  font-weight: 400;
  font-size: ${vw(24)};
  font-style: normal;
  line-height: 1.64;
  margin: 0;
`;

export const JobPosition = styled.h4`
  font-weight: 400;
  font-size: ${vw(24)};
  font-family: '-OCBartok-Regular';
  font-style: normal;
  line-height: 100%;
  text-transform: lowercase;
  margin: 0;
`;

export const RangeInfo = styled(JobPosition)``;
