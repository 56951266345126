import styled from 'styled-components';

import { vw } from '@/styles';

export const Paragraph = styled.p`
  font-size: ${vw(24)};
  line-height: 1.64;
  padding: ${vw(10)} ${vw(20)}};
`;

