import { ISettings } from '@/types';
import { statusMap } from '@/utils';

import { Paragraph } from './Message.styles';
import { MessageProps } from './Message.types';

export const Message = (props: MessageProps) => {
  const currentMessage = statusMap[props.status];
  const settings: ISettings = props.messages[currentMessage];

  return <Paragraph style={{ color: settings.color }}>{settings.long_message}</Paragraph>;
};
