import { ESettingsType } from '@/types';
import { findSettingsPerType, getMailToLink } from '@/utils';

import { Container, MailToUs, SeparatorElement, WhatsApp } from './EmailBottom.styles';
import { EmailBottomProps } from './EmailBottom.types';

export const EmailBottom = (props: EmailBottomProps) => {
  const whatsAppSettings = findSettingsPerType(props.settings, ESettingsType.WHATSAPP);

  return (
    <Container>
      <WhatsApp target="_blank" href={whatsAppSettings?.short_message}>
        WhatsApp
      </WhatsApp>

      <SeparatorElement>／</SeparatorElement>

      <MailToUs href={getMailToLink(props.settings, { fullName: props.fullName, dateRange: props.dateRange })}>
        Mail to us
      </MailToUs>
    </Container>
  );
};
