import { ECalendarStatus, ESettingsType, ISettings } from '@/types';
import { findSettingsPerType, getMailToLink, statusMap } from '@/utils';

import { InfoProps } from './info.types';
import {
  ContactContainer,
  Container,
  FullName,
  JobPosition,
  MailToUs,
  Paragraph,
  PersonContainer,
  RangeInfo,
  SeparatorElement,
  WhatsApp,
} from './styles';

export const Info = (props: InfoProps) => {
  const currentMessage = statusMap[props.status];

  const settings: ISettings = props.messages[currentMessage];

  const whatsAppSettings = findSettingsPerType(props.settings, ESettingsType.WHATSAPP);

  return (
    <Container>
      <PersonContainer>
        <FullName>{props.fullName}</FullName>
        {props.status === ECalendarStatus.DEFAULT ? (
          <JobPosition>{props.jobPosition}</JobPosition>
        ) : (
          <RangeInfo style={{ color: settings.color }}>{settings.short_message}</RangeInfo>
        )}
      </PersonContainer>
      <Paragraph style={{ color: settings.color }}>{settings.long_message}</Paragraph>
      <ContactContainer>
        <MailToUs href={getMailToLink(props.settings, { fullName: props.fullName, dateRange: props.dateRange })}>
          Mail to us
        </MailToUs>
        <SeparatorElement>／</SeparatorElement>
        <WhatsApp target="_blank" href={whatsAppSettings?.short_message}>
          WhatsApp
        </WhatsApp>
      </ContactContainer>
    </Container>
  );
};
