import styled from 'styled-components';

import { rem } from '@/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: ${rem(40)};
`;

export const EventItem = styled.li`
  font-family: 'Inter';
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  margin: ${rem(16)} 0;
  &:last-child {
    margin-bottom: 0;
  }
`;
