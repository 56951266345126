import { ECalendarStatus, ISettings } from '@/types';
import { statusMap } from '@/utils';

import { Container, FullName, JobPosition, RangeInfo } from './Heading.styles';
import { HeadingProps } from './Heading.types';

export const Heading = (props: HeadingProps) => {
  const currentMessage = statusMap[props.status];

  const settings: ISettings = props.messages[currentMessage];

  return (
    <Container>
      <FullName>{props.fullName}</FullName>
      {props.status === ECalendarStatus.DEFAULT ? (
        <JobPosition>{props.jobPosition}</JobPosition>
      ) : (
        <RangeInfo style={{ color: settings.color }}>{settings.short_message}</RangeInfo>
      )}
    </Container>
  );
};
