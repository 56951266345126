import { Calendar as ReactCalendar } from 'react-calendar';
import { isMobile } from 'react-device-detect';

import ChevronLeft from '@/assets/icons/chevron-left.svg';
import ChevronDoubleLeft from '@/assets/icons/chevron-double-left.svg';
import ChevronRight from '@/assets/icons/chevron-right.svg';
import ChevronDoubleRight from '@/assets/icons/chevron-double-right.svg';

import { CalendarProps } from './Calendar.types';

import 'react-calendar/dist/Calendar.css';

export const Calendar = (props: CalendarProps) => {
  return (
    <ReactCalendar
      className={`jugglers-calendar ${props.className || ''}`}
      onChange={(dates) => {
        props.setDateRange(dates);
      }}
      showWeekNumbers={false}
      navigationLabel={(value) => {
        const date = new Date(value.date);
        const month = date.toLocaleString('default', { month: 'short' });

        if (isMobile) {
          return `${month} ${date.getFullYear()}`;
        }

        return value.label;
      }}
      view="month"
      daysStyles={props.daysStyles}
      selectRange={true}
      showFixedNumberOfWeeks={false}
      showNeighboringMonth={false}
      prevLabel={<img src={ChevronLeft} alt="" />}
      prev2Label={<img src={ChevronDoubleLeft} alt="" />}
      nextLabel={<img src={ChevronRight} alt="" />}
      next2Label={<img src={ChevronDoubleRight} alt="" />}
      value={props.dateRange}
    />
  );
};
