import styled from 'styled-components';

import { vw } from '@/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${vw(10)} ${vw(22)} ${vw(35)};
`;

export const LinkUnderline = styled.a`
  position: relative;
  &:after {
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #fff;
    left: 0;
    bottom: 1px;
    opacity: 0;
  }
  &:hover:after {
    content: '';
    opacity: 1;
  }
`;

export const MailToUs = styled(LinkUnderline)`
  font-size: ${vw(24)};
  line-height: 1.64;
  border-radius: ${vw(66)};
  padding: ${vw(16)} ${vw(24)};
`;

export const SeparatorElement = styled.span`
  min-width: ${vw(42)};
  font-size: ${vw(24)};
  line-height: 1.64;
  text-align: center;
`;


export const WhatsApp = styled(LinkUnderline)`
  font-size: ${vw(24)};
  line-height: 1.64;
  text-align: center;
  padding: ${vw(16)} ${vw(12)};
`;
