import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import 'react-app-polyfill/stable';

import { Panel } from './Panel';
import reportWebVitals from './reportWebVitals';

import './assets/scss/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<Panel />} /> */}
      <Route path="/:creatorId" element={<Panel />} />
    </Routes>
  </BrowserRouter>,
);

reportWebVitals();
