import styled from 'styled-components';

import { rem } from '@/styles';

export const Container = styled.div`
  max-width: ${rem(1550)};
  display: flex;
  justify-content: space-between;
  margin: ${rem(20)} auto;
  padding: 0 ${rem(20)};
`;

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ContainerMobile = styled.div`
  width: 100%;
  padding: 0;
`;
