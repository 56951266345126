import { ESettingsType, IEmailProps, ISettings } from '@/types';

import { getDateForEmail } from './date.util';
import { findSettingsPerType } from './settings.util';

export function getEmailSchemeBody(props: IEmailProps): string {
  return (
    `Creator: ${props.fullName}%0D%0A%0D%0A` +
    `Project start date: ${getDateForEmail(props.dateRange, 0)}%0D%0A%0D%0A` +
    `Project end date: ${getDateForEmail(props.dateRange, 1)}%0D%0A%0D%0A` +
    `Project name:%0D%0A%0D%0A` +
    `Market/territory:%0D%0A%0D%0A` +
    `Contact name/name of the company who sent a request:%0D%0A%0D%0A` +
    `Other details they find useful:%0D%0A`
  );
}

export function getMailToLink(settings: ISettings[], props: IEmailProps) {
  const emailSettings = findSettingsPerType(settings, ESettingsType.EMAIL_CONTACT);

  return `mailto:${emailSettings?.email}?subject=Jugglers Airtable!&body=${getEmailSchemeBody(props)}`;
}
