import { IEvent } from '@/types';

import { Container, EventItem, EventList } from './EventsBottom.styles';
import { EventsBottomProps } from './EventsBottom.types';

export const EventsBottom = (props: EventsBottomProps) => {
  return (
    <Container>
      <EventList>
        {props.events.map((event: IEvent, index: number) => (
          <EventItem key={`Event${index}`} style={{ color: event.textColor }}>
            {event.name}
          </EventItem>
        ))}
      </EventList>
    </Container>
  );
};
