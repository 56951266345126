import { IEvent } from '@/types';
import { getRange } from './date.util';

export function mapEventDatesWithStyles(event: IEvent) {
  const results = {};
  const ranges = getRange(event.startDate, event.endDate);
  ranges.forEach((date) => {
    results[date] = {
      color: event.textColor,
    };
  });
  return results;
}

export function mapEventsDatesWithStyles(events: IEvent[]) {
  let results = {};

  events.forEach((event) => {
    results = { ...results, ...mapEventDatesWithStyles(event) };
  });

  return results;
}
