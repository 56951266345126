import moment from 'moment';

export const DATE_FORMAT = 'DDMMYYYY';

export function getRange(startDate: string, endDate: string): string[] {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, 'days');
  const dates: string[] = [];

  for (let i = 0; i <= diff; i++) {
    dates.push(moment(startDate).add(i, 'days').format(DATE_FORMAT));
  }

  return dates;
}

export function getFormattedDate(date: Date) {
  return moment(date).format(DATE_FORMAT);
}

export function getFormattedDates(values: Date[]) {
  return values.map(value => getFormattedDate(value));
}

export function getDateForEmail(values: Date[] = [], pos = 0): string {
  return values.length === 2 ? formatDateForEmail(values[pos]) : '';
}

export function formatDateForEmail(date: Date): string {
  return date ? moment(date).format('DD-MM-YYYY') : '';
}