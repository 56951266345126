import { ECalendarStatus, ESettingsType, ISettings } from '@/types';

export function getMessageTypes(settings: ISettings[] = []) {
  const results = {};
  const types: ESettingsType[] = [
    ESettingsType.MESSAGE_DEFAULT,
    ESettingsType.MESSAGE_ERROR,
    ESettingsType.MESSAGE_SUCCESS,
  ];
  types.forEach((type) => {
    results[type] = { ...(settings.find((value) => value.type === type) || {}) };
  });
  return results;
}

export function findSettingsPerType(settings: ISettings[], type: ESettingsType): ISettings | undefined {
  return settings.find((obj) => obj.type === type);
}

export const statusMap = {
  [ECalendarStatus.INVALID]: ESettingsType.MESSAGE_ERROR,
  [ECalendarStatus.VALID]: ESettingsType.MESSAGE_SUCCESS,
  [ECalendarStatus.DEFAULT]: ESettingsType.MESSAGE_DEFAULT,
};
