import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Calendar } from '@/components/calendar/Calendar';
import { EmailBottom } from '@/components/email-bottom/EmailBottom';
import { EventsBottom } from '@/components/events-bottom/EventsBottom';
import { Message } from '@/components/message/Message';
import { Info } from '@/components/info/Info';
import { Events } from '@/components/events/Events';
import { Heading } from '@/components/heading/Heading';

import { getMessageTypes, getRange, mapEventsDatesWithStyles, request } from './utils';
import { ECalendarStatus, ICreator, ISettings } from './types';

import { Container, CalendarWrapper, ContainerMobile } from './panel.styles';

export const Panel = () => {
  const [creator, setCreator] = useState<ICreator>();
  const [settings, setSettings] = useState<ISettings[]>([]);
  const [dateRange, setDateRange] = useState<Array<Date>>();
  const [daysStyles, setDaysStyles] = useState({});
  const [status, setStatus] = useState<ECalendarStatus>(ECalendarStatus.DEFAULT);

  const params = useParams();

  useEffect(() => {
    if (params.creatorId) {
      getApiCall();
    }
  }, [params.creatorId]);

  async function getApiCall() {
    try {
      const results = await Promise.all([
        request({ method: 'get', url: 'airtable/settings' }),
        request({ method: 'get', url: `airtable/creators/${params.creatorId}` }),
      ]);

      const settingsResult = results[0] ? results[0].results : null;
      const creatorResult = results[1] ? results[1].results : null;

      if (creatorResult) {
        setDaysStyles(mapEventsDatesWithStyles(creatorResult.events));
      }

      setCreator(creatorResult);
      setSettings(settingsResult);
    } catch {}
  }

  function updateDateRange(changes) {
    const dates = Object.keys(daysStyles);
    const ranges = getRange(changes[0], changes[1]);

    const isFounded = dates.some((date) => ranges.includes(date));

    setStatus(isFounded ? ECalendarStatus.INVALID : ECalendarStatus.VALID);
    setDateRange(changes);
  }

  return isMobile ? (
    <ContainerMobile>
      {creator && settings && (
        <Heading
          fullName={creator?.fullName}
          jobPosition={creator?.profession.join(' ')}
          status={status}
          messages={getMessageTypes(settings)}
        />
      )}
      {creator && (
        <Fragment>
          <Calendar
            className="jugglers-calendar-mobile"
            events={creator?.events}
            daysStyles={daysStyles}
            dateRange={dateRange}
            setDateRange={updateDateRange}
          />
          <EventsBottom events={creator?.events}></EventsBottom>
          <Message status={status} messages={getMessageTypes(settings)} />
          <EmailBottom
            fullName={creator?.fullName || ''}
            dateRange={dateRange || []}
            settings={settings}
            status={status}
          />
        </Fragment>
      )}
    </ContainerMobile>
  ) : (
    <Container>
      {creator && settings && (
        <Info
          fullName={creator?.fullName}
          jobPosition={creator?.profession.join(' ')}
          dateRange={dateRange || []}
          status={status}
          messages={getMessageTypes(settings)}
          settings={settings}
        />
      )}
      <CalendarWrapper>
        {creator && <Events events={creator?.events} />}
        {creator && (
          <Calendar
            events={creator?.events}
            daysStyles={daysStyles}
            dateRange={dateRange}
            setDateRange={updateDateRange}
          />
        )}
      </CalendarWrapper>
    </Container>
  );
};
