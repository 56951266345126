import { IEvent } from '@/types';

import { Container, EventItem, EventList } from './styles';

import { EventsProps } from './Events.types';

export const Events = (props: EventsProps) => {
  return (
    <Container>
      <EventList>
        {props.events.map((event: IEvent, index: number) => (
          <EventItem key={`Event${index}`} style={{ color: event.textColor }}>
            {event.name}
          </EventItem>
        ))}
      </EventList>
    </Container>
  );
};
